import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

export default function Accordion({ items }) {
  return (
    <div className="w-full">
      <div className="mx-auto w-full max-w-md rounded-2xl">
        {items &&
          items.map((item, index) => {
            return (
              <div key={index}>
                {item.text !== "" && (
                  <Disclosure defaultOpen={true}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 dark:bg-slate-700 px-4 py-2 text-left text-sm font-medium text-slate-800 dark:text-slate-100 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75">
                          <span>{item.title}</span>
                          <ChevronUpIcon
                            className={`${
                              !open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-slate-800 dark:text-slate-400`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-2 pb-4 text-sm text-slate-800 dark:text-slate-300">
                          {item.text.split(".").map((sentence, index) => {
                            return (
                              <div key={index + "s"}>
                                <span key={index}>{sentence}</span>
                                <br />
                              </div>
                            );
                          })}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
