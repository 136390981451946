import React, { useContext } from "react";
import ToggleTheme from "./themeToggle";
import { RefreshIcon } from "@heroicons/react/outline";
import { AuthContext } from "../context/auth";
const NavActions = ({ spacing, size }) => {
  const containerDimensions = size === "sm" ? "h-12 w-12" : "h-14 w-14";
  const iconDimensions = size === "sm" ? "h-6 w-6" : "h-8 w-8";
  const { signOutUser } = useContext(AuthContext);
  return (
    <div className={`${spacing} flex items-center justify-center `}>
      <div
        onClick={() => window.location.reload()}
        className={` ${containerDimensions} rounded-2xl dark:bg-slate-900 border-2 dark:border-slate-600 shadow-xl flex items-center justify-center hover:bg-slate-200 dark:hover:bg-slate-300 hover:cursor-pointer`}
      >
        <RefreshIcon className={`${iconDimensions}  text-slate-500`} />
      </div>
      <div
        onClick={() => signOutUser()}
        className={` ${containerDimensions} rounded-2xl dark:bg-slate-900 border-2 dark:border-slate-600 shadow-xl flex items-center justify-center hover:bg-slate-200 dark:hover:bg-slate-300 hover:cursor-pointer`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`${iconDimensions} text-slate-500`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
          />
        </svg>
      </div>

      <ToggleTheme
        containerDimensions={containerDimensions}
        iconDimensions={iconDimensions}
      />
    </div>
  );
};
export default NavActions;
