import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD5aCe43-Bbl-a9No4QBKUWlq9bAMobmDI",
  authDomain: "quantized-self.firebaseapp.com",
  projectId: "quantized-self",
  storageBucket: "quantized-self.appspot.com",
  messagingSenderId: "1010564726113",
  appId: "1:1010564726113:web:7bc050c27b102522475920",
  measurementId: "G-499GHHVY11",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const auth = getAuth(app);
