import React from "react";

const ProgressBar = ({ percentage, color = "cyan" }) => {
  return (
    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
      <div
        className={`bg-${color}-500 text-xs font-medium text-white text-center p-0.5 leading-none rounded-full`}
        style={{ width: percentage <= 100 ? percentage : 100 + "%" }}
      >
        {percentage <= 100 ? percentage : 100}%
      </div>
    </div>
  );
};
export default ProgressBar;
