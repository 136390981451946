import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/auth";
import { DatabaseProvider } from "./context/database";
import Goals from "./views/Goals";
import GoalForm from "./forms/GoalForm";
import Dashboard from "./views/Dashboard";
import Habits from "./views/Habits";
import Journal from "./views/DailyJournal";
import JournalView from "./views/JournalView";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <DatabaseProvider>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Dashboard />} />
              <Route path="new" element={<GoalForm />} />
              <Route path="edit" element={<GoalForm />} />
              <Route path="/journal" element={<JournalView />} />
              {/* <Route path="goals/edit" element={<GoalForm />} />
              <Route path="goals/new" element={<GoalForm />} />
              <Route path="habits" element={<Habits />} />
              <Route path="habit/edit" element={<Habits />} />
              <Route path="habit/new" element={<Habits />} /> */}
              <Route path="*" element={null} />
            </Route>
          </Routes>
        </DatabaseProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
