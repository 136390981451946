import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDocs,
  serverTimestamp,
  query,
  where,
  updateDoc,
  deleteDoc,
  writeBatch,
  runTransaction,
  arrayUnion,
  arrayRemove,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../Firebase";
import { AuthContext } from "./auth";

import React, { createContext, useContext, useEffect, useState } from "react";
import { getWeekDates } from "../utilityFunctions";
// import { AuthContext } from "./auth";
// import _ from "lodash";
export const DatabaseContext = createContext();

export const DatabaseProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  // Notification
  const [notification, setNotification] = useState({ type: null, title: "" });
  useEffect(() => {
    if (notification.type !== null) {
      const timer = setTimeout(() => {
        setNotification({ type: null, title: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);
  const saveSuccess = () => {
    setNotification({ type: "success", title: "Saved Successfully" });
  };
  const saveFailed = () => {
    setNotification({ type: "failed", title: "Failed to Save" });
  };

  // GET REQUESTS
  const getUser = async () => {
    const q = query(
      collection(db, "users"),
      where("userId", "==", currentUser.uid)
    );
    let data;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = { id: doc.id, ...doc.data() };
    });
    return data;
  };

  const getGoals = async () => {
    const q = query(
      collection(db, "goals"),
      where("userId", "==", currentUser.uid)
    );

    const querySnapshot = await getDocs(q);
    let goals = [];
    querySnapshot.forEach(async (doc) => {
      goals.push({ id: doc.id, ...doc.data() });
    });
    return goals;
  };
  const getHabits = async (goalId) => {
    const q = query(collection(db, "goals", goalId, "habits"));

    const querySnapshot = await getDocs(q);

    let habits = [];
    querySnapshot.forEach((doc) => {
      habits.push({ id: doc.id, ...doc.data(), goalId });
    });
    return habits;
  };
  const getActivity = async (type) => {
    const { monday, sunday } = getWeekDates(type);
    const q = query(
      collection(db, "activity"),
      where("userId", "==", currentUser.uid),
      where("timestamp", ">=", monday),
      where("timestamp", "<=", sunday)
    );
    // const q = query(collection(db, "habits"), where("userId", "==", user.uid));

    const querySnapshot = await getDocs(q);

    let activity = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      activity.push({ activityId: doc.id, ...doc.data() });
    });
    return activity;
  };
  const getDailyJournalEntry = async (date, userId) => {
    var start = new Date(date);
    start.setHours(0, 0, 0, 0);

    var end = new Date(date);
    end.setHours(23, 59, 59, 999);

    const q = query(
      collection(db, "journals", currentUser.uid, "daily"),
      where("timestamp", ">=", start),
      where("timestamp", "<=", end)
    );
    let data;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = { id: doc.id, ...doc.data() };
    });
    return data;
  };
  const getWeeklyJournalEntry = async (date, userId, week) => {
    const { monday, sunday } = getWeekDates(week);

    var start = new Date(monday);
    start.setHours(0, 0, 0, 0);

    var end = new Date(sunday);
    end.setHours(23, 59, 59, 999);

    const q = query(
      collection(db, "journals", currentUser.uid, "weekly"),
      where("timestamp", ">=", start),
      where("timestamp", "<=", end)
    );
    let data;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = { id: doc.id, ...doc.data() };
    });
    return data;
  };

  // POST REQUESTS
  const addGoal = async (data, habits) => {
    console.log("running add goal");
    const docRef = doc(collection(db, "goals"));
    await setDoc(docRef, {
      ...data,
      timestamp: serverTimestamp(),
      userId: currentUser.uid,
    })
      .then(async () => {
        if (habits.length > 0) {
          for (let habit of habits) {
            await addHabit(habit, docRef.id);
          }
        }
        const dateTodayFormatted = `${
          new Date().getMonth() + 1
        }_${new Date().getDate()}_${new Date().getFullYear()}`;
        saveSuccess();
        saveActivity("goal", data.name, dateTodayFormatted, docRef.id);
      })
      .catch((e) => saveFailed());
  };

  const addHabit = async (habit, goalId) => {
    const docRef = collection(db, "goals", goalId, "habits");
    await addDoc(docRef, {
      name: habit.name,
      timestamp: serverTimestamp(),
      completed: [],
      userId: currentUser.uid,
    })
      .then((response) => {})
      .catch((e) => saveFailed());
  };

  const addDailyJournalEntry = async (
    dailyReview,
    dailyLessons,
    date,
    formattedDate,
    user
  ) => {
    const docRef = doc(collection(db, "journals", currentUser.uid, "daily"));
    await setDoc(docRef, {
      dailyLessons,
      dailyReview,
      timestamp: Timestamp.fromDate(date),
      userId: user.userId,
    });
    saveSuccess();
    saveActivity(
      "journal",
      dailyReview + "|" + dailyLessons,
      formattedDate,
      docRef.id,
      "daily"
    );
    completedDailyJournal(true, formattedDate, docRef.id);
  };
  const addWeeklyJournalEntry = async (
    positives,
    negatives,
    lessons,
    date,
    formattedDate,
    user,
    week
  ) => {
    const { monday, sunday } = getWeekDates(week);

    const docRef = doc(collection(db, "journals", currentUser.uid, "weekly"));
    await setDoc(docRef, {
      positives,
      negatives,
      lessons,
      monday,
      sunday,
      timestamp: Timestamp.fromDate(sunday),
      userId: user.userId,
    });
    saveSuccess();
    saveActivity(
      "journal",
      positives + "|" + negatives + "|" + lessons,
      formattedDate,
      docRef.id,
      "weekly"
    );

    await completedWeeklyJournal(true, formattedDate, user.id);
  };

  const saveActivity = async (type, text, formattedDate, id, note = "") => {
    const docRef = doc(collection(db, "activity"));
    await setDoc(docRef, {
      text,
      timestamp: serverTimestamp(),
      date: formattedDate,
      type,
      userId: currentUser.uid,
      refId: id,
      note,
    })
      .then((response) => {})
      .catch((e) => saveFailed());
  };

  // UPDATE REQUESTS
  const updateHabit = async (habit, goalId) => {
    const docRef = doc(db, "goals", goalId, "habits", habit.id);
    await updateDoc(docRef, {
      name: habit.name,
    })
      .then((response) => {})
      .catch((e) => console.log(e));
  };
  const updateGoal = async (data, habits, id) => {
    console.log("running update goal", data, habits, id);

    const docRef = doc(db, "goals", id);

    await updateDoc(docRef, {
      ...data,
    })
      .then(async () => {
        for (let habit of habits) {
          if (habit.id) {
            await updateHabit(habit, id);
          } else {
            await addHabit(habit, id);
          }
        }

        saveSuccess();
      })
      .catch((e) => saveFailed());
  };
  const updateDailyJournalEntry = async (dailyReview, dailyLessons, id) => {
    const docRef = doc(db, "journals", currentUser.uid, "daily", id);
    await updateDoc(docRef, {
      dailyReview,
      dailyLessons,
    })
      .then((response) => {
        saveSuccess();
        updateActivity(dailyReview + "|" + dailyLessons, id);
      })
      .catch((e) => saveFailed());
  };
  const updateWeeklyJournalEntry = async (
    positives,
    negatives,
    lessons,
    id
  ) => {
    const docRef = doc(db, "journals", currentUser.uid, "weekly", id);
    await updateDoc(docRef, {
      positives,
      negatives,
      lessons,
    })
      .then((response) => {
        saveSuccess();
        updateActivity(positives + "|" + negatives + "|" + lessons, id);
      })
      .catch((e) => saveFailed());
  };
  const updateActivity = async (text, id) => {
    const q = query(collection(db, "activity"), where("refId", "==", id));
    let activityId;
    const activityDoc = await getDocs(q);
    activityDoc.forEach((doc) => {
      activityId = doc.id;
    });

    const docRef = doc(db, "activity", activityId);
    await updateDoc(docRef, { text })
      .then()
      .catch(() => saveFailed());
  };
  // POST Completed Array
  const completedHabit = async (habit, value, date) => {
    const docRef = doc(db, "goals", habit.goalId, "habits", habit.id);
    await updateDoc(docRef, {
      completed: value ? arrayUnion(date) : arrayRemove(date),
    })
      .then((response) => {
        saveSuccess();
        if (value) {
          saveActivity("habit", habit.name, date, habit.id);
        }
      })
      .catch((e) => saveFailed());
  };
  const completedDailyJournal = async (value, date) => {
    const docRef = doc(db, "journals", currentUser.uid);
    await updateDoc(docRef, {
      dailyEntries: value ? arrayUnion(date) : arrayRemove(date),
    })
      .then((response) => {})
      .catch((e) => console.log(e));
  };
  const completedWeeklyJournal = async (value, date) => {
    const docRef = doc(db, "journals", currentUser.uid);
    await updateDoc(docRef, {
      weeklyEntries: value ? arrayUnion(date) : arrayRemove(date),
    })
      .then((response) => {})
      .catch((e) => console.log(e));
  };

  // DELETE REQUESTS
  const deleteGoal = async (goalId, habits) => {
    const batch = writeBatch(db);
    const goalRef = doc(db, "goals", goalId);
    batch.delete(goalRef);
    for (let habit of habits) {
      const habitRef = doc(db, "habits", habit.habitId);
      batch.delete(habitRef);
    }
    await batch.commit();
    saveSuccess();
  };

  return (
    <DatabaseContext.Provider
      value={{
        addGoal,
        getGoals,
        getHabits,
        updateGoal,
        deleteGoal,
        completedHabit,
        getUser,
        addDailyJournalEntry,
        addWeeklyJournalEntry,
        getDailyJournalEntry,
        getWeeklyJournalEntry,
        updateDailyJournalEntry,
        updateWeeklyJournalEntry,
        notification,
        setNotification,
        getActivity,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};
