import React, { useState, useEffect, useContext } from "react";
import HeaderWithAction from "../components/Headers/headerWithAction";
import Calendar from "../components/calendar";
import JournalForm from "../forms/DailyJournalForm";
import { CalendarIcon } from "@heroicons/react/outline";

const DailyJournal = ({ user }) => {
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);

  // FORMAT to MM/DD/YYYY
  const dateTodayFormatted = `${
    date.getMonth() + 1
  }_${date.getDate()}_${date.getFullYear()}`;

  // CHANGE Date and GET Entry
  const changeDate = (item, date, index) => {
    const formatted = date.replace(/_/g, "/");
    setDate(new Date(formatted));
  };

  return (
    <div>
      <HeaderWithAction
        size="2xl"
        title={`Entry - ${dateTodayFormatted.replace(/_/g, "/")}`}
        action={
          <div
            onClick={() => setShowCalendar(!showCalendar)}
            className="hover:cursor-pointer inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-cyan-500 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            <CalendarIcon className="h-4 w-4 text-white" />
          </div>
        }
        border={true}
        spacing="pb-2 mb-4"
      />
      <JournalForm
        date={date}
        user={user}
        dateTodayFormatted={dateTodayFormatted}
      />
      <div className={showCalendar ? "block" : "hidden"}>
        <Calendar
          item={user ? user.entries : []}
          date={date}
          onClickDay={changeDate}
          index={null}
        />
      </div>
    </div>
  );
};
export default DailyJournal;
// className="block p-4  bg-white rounded-lg  shadow-sm  dark:bg-slate-800 ring-1 ring-slate-900/5 dark:shadow-slate-800/80 flex flex-col justify-between"
