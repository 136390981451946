import React, { useState } from "react";

import EmptyState from "./emptyState";
import HeaderWithAction from "./Headers/headerWithAction";
import GoalCard from "./goalCard";
import HabitCard from "./habitCard";
import CardSkeleton from "./cardSkeleton";
import PlusButton from "./plusBtn";

import { CalendarIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";

const HeaderWithGridCard = ({
  title,
  loading,
  list,
  date,
  updateState,
  markComplete,
  habits,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const dateTodayFormatted = `${
    date.getMonth() + 1
  }_${date.getDate()}_${date.getFullYear()}`;
  return (
    <div className="w-auto h-auto bg-white dark:bg-gray-900 ">
      <HeaderWithAction
        size="3xl"
        title={title}
        action={
          <div className="flex flex-row gap-5 items-center justify-center">
            <div
              onClick={() => setShowDetails(!showDetails)}
              className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-cyan-500 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            >
              {showDetails ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                  />
                </svg>
              )}
            </div>
            {title === "Goals" && <PlusButton path="new" />}
          </div>
        }
        border={true}
        spacing="pb-4 mb-4"
      />
      {loading ? (
        <CardSkeleton spacing="mt-4" />
      ) : (
        <>
          {list && list.length > 0 ? (
            <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
              {list.map((item, index) => {
                return (
                  <div key={index}>
                    {title === "Habits" ? (
                      <HabitCard
                        habit={item}
                        date={dateTodayFormatted}
                        index={index}
                        key={index}
                        setValue={updateState}
                        showCalendar={showDetails}
                        markComplete={markComplete}
                      />
                    ) : (
                      <GoalCard
                        habits={habits}
                        {...item}
                        key={index}
                        collapseAll={showDetails}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <EmptyState
              icon={
                <StarIcon
                  className="-ml-1 mr-2 h-10 w-10 text-cyan-400"
                  aria-hidden="true"
                />
              }
              title="No Goals Yet"
              text="Start adding goals to get started becoming the new you!"
              btnAction={() => null}
              btnTitle="Add Goal"
            />
          )}
        </>
      )}
    </div>
  );
};
export default HeaderWithGridCard;
