/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  HomeIcon,
  MenuIcon,
  XIcon,
  ArrowLeftOnRectangle,
} from "@heroicons/react/outline";
import { SelectorIcon } from "@heroicons/react/solid";
import {
  Redirect,
  Routes,
  Route,
  Link,
  useLocation,
  Outlet,
} from "react-router-dom";
import QuantizedLogo from "./assets/logo.png";
// import Settings from "./views/settings";
import ToggleTheme from "./components/themeToggle";
// import AsideRight from "./views/aside_right/View";
import { AuthContext } from "./context/auth";
import { DatabaseContext } from "./context/database";
import MobileNavBar from "./components/mobileNavBar";
import NavLinks from "./components/navLinks";
import NavActions from "./components/navActions";
import PopUpNotification from "./components/popUpNotification";

// import ProfilePhoto from "./components/profilePhoto";

export default function HomeLayout() {
  const [user, setUser] = useState(null);
  const { getUser, notification, setNotification } = useContext(
    DatabaseContext
  );

  const { signOutUser, currentUser } = useContext(AuthContext);
  const location = useLocation();
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      handleGetUser();
    }
  }, [currentUser]);
  const handleGetUser = async () => {
    const request = await getUser();
    if (request && request.id) {
      setUser(request);
    }
  };
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <div className="h-full flex ">
        <div className="fixed z-40 md:hidden w-full px-4 py-2 bg-white dark:bg-slate-900">
          <MobileNavBar user={user} />
        </div>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:flex-shrink-0 dark:bg-slate-900">
          <div className="flex flex-col w-80">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 ">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-5 mb-16">
                  <span className="flex w-full justify-between items-center">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                      <img
                        className="h-14 rounded-full "
                        src={QuantizedLogo}
                        alt="Wildflower"
                      />
                      <span className="flex-1 flex flex-col min-w-0">
                        <span className="text-gray-900 text:md sm:text-lg font-medium truncate dark:text-white">
                          {user && user.first + " " + user.last}
                        </span>
                        <span className="text-gray-500 text-sm truncate dark:text-white">
                          {user && user.email}
                        </span>
                      </span>
                    </span>
                  </span>
                </div>

                <NavLinks />
                <NavActions spacing="mb-5 gap-5" size="md" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="flex-1 relative z-0 flex overflow-hidden dark:bg-slate-900">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
              <div className="absolute inset-0 py-6 px-1 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  <br />
                  <Outlet context={user} />
                  {notification.type !== null && (
                    <PopUpNotification
                      notification={notification}
                      setNotification={setNotification}
                    />
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
