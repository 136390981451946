import React, { useState, useEffect, useContext } from "react";
import { DatabaseContext } from "../context/database";
import BasicHeader from "../components/Headers/basicHeader";
import TextAreaWithAction from "../components/Inputs/textAreaWithAction";
const DailyJournalForm = ({ date, user, dateTodayFormatted }) => {
  const {
    addDailyJournalEntry,
    getDailyJournalEntry,
    updateDailyJournalEntry,
  } = useContext(DatabaseContext);
  const [dailyReview, setDailyReview] = useState("");
  const [dailyLessons, setDailyLessons] = useState("");
  const [entryDocId, setEntryDocId] = useState(null);

  useEffect(() => {
    if (date && user) {
      getJournalData();
    }
  }, [date, user]);

  // GET DATE Entry
  const getJournalData = async () => {
    console.log("running get journal data");
    const request = await getDailyJournalEntry(date, user.userId);
    if (request && request.id) {
      setDailyReview(request.dailyReview);
      setDailyLessons(request.dailyLessons);
      setEntryDocId(request.id);
    } else {
      if (entryDocId) {
        setEntryDocId(null);
      }
      setDailyLessons("");
      setDailyReview("");
    }
  };

  // POST Date Entry
  const handleSubmit = () => {
    if (entryDocId) {
      updateDailyJournalEntry(dailyReview, dailyLessons, entryDocId);
    } else {
      addDailyJournalEntry(
        dailyReview,
        dailyLessons,
        date,
        dateTodayFormatted,
        user
      );
    }
    getJournalData();
  };
  return (
    <div>
      <BasicHeader title="What did you do today?" size="lg" />

      <TextAreaWithAction
        handleSubmit={handleSubmit}
        entry={dailyReview}
        setEntry={setDailyReview}
        action={false}
      />
      <br />
      <BasicHeader title="Any wins, mistakes, lessons?" size="lg" />

      <TextAreaWithAction
        handleSubmit={handleSubmit}
        entry={dailyLessons}
        setEntry={setDailyLessons}
        action={false}
      />
      <div className="flex justify-end mt-3">
        <button
          type="button"
          onClick={() => handleSubmit()}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none  focus:ring-cyan-500"
        >
          Post
        </button>
      </div>
    </div>
  );
};
export default DailyJournalForm;
