import React from "react";

const SelectInput = ({ value, setValue, label, name, options }) => {
  return (
    <div className="mt-4">
      <label
        htmlFor={`${name}-name`}
        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
      >
        {label}
      </label>
      <div className="mt-1">
        <select
          id={name}
          name={name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoComplete={`${name}-name`}
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-cyan-500 dark:focus:border-cyan-500"
        >
          {options &&
            options.map((option, index) => {
              return <option key={index}>{option}</option>;
            })}
        </select>
      </div>
    </div>
  );
};

export default SelectInput;
