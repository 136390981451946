import { summary } from "date-streaks";
export const getDiffDays = (date1, date2) => {
  let oneDayMilliseconds = 1000 * 60 * 60 * 24;

  let diff = Math.round(
    new Date(date1.getTime() - date2.getTime()) / oneDayMilliseconds
  );
  return diff;
};

export const getGoalProgress = (date1, date2) => {
  if (new Date(date1) > new Date()) {
    return 0;
  }
  const day = getDiffDays(new Date(), new Date(date1));
  const diff = getDiffDays(new Date(date2), new Date(date1));
  const progress = Math.round((day / diff) * 100);
  return progress;
};

export const getAllDaysInMonth = (inputDate, activeDates) => {
  const dateParts = inputDate.split("_");
  const date = new Date(dateParts[2], dateParts[0], 1);
  const newMonth = new Date(dateParts[2], +dateParts[0] + 1, 1);
  const previousMonth = new Date(dateParts[2], dateParts[0], 1);
  previousMonth.setDate(previousMonth.getDate() - 1);
  const dates = [];
  const nextMonthDates = [];
  const prevMonthDates = [];
  const firstOfMonth = date.getDay() !== 1 ? 7 - date.getDay() : false;
  if (firstOfMonth) {
    while (prevMonthDates.length < firstOfMonth) {
      const dateTodayFormatted = `${previousMonth.getMonth()}_${previousMonth.getDate()}_${previousMonth.getFullYear()}`;

      prevMonthDates.push({
        date: dateTodayFormatted,
        isCurrentMonth: false,
        isSelected: false,
      });
      previousMonth.setDate(previousMonth.getDate() - 1);
    }
  }

  while (date.getMonth() === +dateParts[0]) {
    const dateTodayFormatted = `${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getFullYear()}`;
    const isSelected =
      activeDates.indexOf(dateTodayFormatted) === -1 ? false : true;
    dates.push({
      date: dateTodayFormatted,
      isCurrentMonth: true,
      isSelected,
      isToday: date.getDate() === new Date().getDate(),
      disabled: new Date(date) > new Date().setHours(0, 0, 0, 0),
    });
    date.setDate(date.getDate() + 1);
  }
  let limits = firstOfMonth ? firstOfMonth - 1 : dates.length === 30 ? 5 : 4;
  while (nextMonthDates.length < limits) {
    const dateTodayFormatted = `${
      newMonth.getMonth() + 1
    }_${newMonth.getDate()}_${newMonth.getFullYear()}`;

    nextMonthDates.push({
      date: dateTodayFormatted,
      isCurrentMonth: false,
      isSelected: false,
      disabled: new Date(newMonth) > new Date().setHours(0, 0, 0, 0),
    });
    newMonth.setDate(newMonth.getDate() + 1);
  }

  return [...prevMonthDates.reverse(), ...dates, ...nextMonthDates];
};

export const currentStreak = (arr) => {
  let dates = [];
  for (let date of arr) {
    const formatted = date.replace(/_/g, "/");
    dates.push(new Date(formatted));
  }
  const streak = summary(dates);

  return streak.currentStreak;
};

export const getWeekDates = (type) => {
  const now = new Date();

  const today =
    type === "current"
      ? now
      : new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  //
  const first = today.getDate() - (today.getDay() + 6) + 1;
  const sunday = new Date(today.setDate(first + 5));
  sunday.setUTCHours(23, 59, 59, 999);
  const monday = new Date(today.setDate(first));
  monday.setUTCHours(0, 0, 0, 0);

  return { monday, sunday };
};

export const organizeActivity = (activityArray) => {
  let organized = {};

  for (let item of activityArray) {
    let date = item.date;
    let type = item.type;
    let text = item.text;
    // habit and array exists
    if (organized[date]) {
      if (type === "habit" && organized[date][type]) {
        organized[date][type].push(text);
      } else {
        if (type === "habit") {
          organized[date][type] = [text];
        } else {
          organized[date][type] = text;
        }
      }
    } else {
      if (type === "habit") {
        organized[date] = { [type]: [text] };
      } else {
        organized[date] = { [type]: text };
      }
    }
  }
  return organized;
};

// function to change date to different formats
