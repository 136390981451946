import React, { useState, useEffect } from "react";
import CheckboxInput from "./Inputs/checkboxInput";
import Calendar from "./calendar";
import Badge from "../components/badge";

const HabitCard = ({
  setValue,
  habit,
  index,
  date,
  showCalendar,
  markComplete,
}) => {
  return (
    <div className="w-full bg-white dark:bg-slate-800 shadow-sm rounded-lg  px-4 py-3 ring-1 ring-slate-900/5 dark:shadow-slate-800/80">
      <div className=" flex flex-row items-center justify-between space-x-3 ">
        <div className=" flex flex-row items-center justify-between space-x-3 ">
          <CheckboxInput
            value={habit.completed.indexOf(date) === -1 ? false : true}
            setValue={(e) => setValue(e, index)}
          />
          <p className="text-lg font-medium text-gray-900 dark:text-white w-full">
            {habit.name}
          </p>
        </div>
        <Badge
          text={habit.streak ? `Streak +${habit.streak}` : "No Streak"}
          color={habit.streak ? "green" : "yellow"}
        />
      </div>
      <div className={showCalendar ? "block" : "hidden"}>
        <Calendar
          item={habit.completed}
          date={date}
          onClickDay={markComplete}
          index={index}
        />
      </div>
    </div>
  );
};
export default HabitCard;
