/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useState, useEffect } from "react";
import { DatabaseContext } from "./context/database";
import { Routes, Route, Link } from "react-router-dom";
import { AuthContext } from "./context/auth";
import Authentication from "./views/Authentication";
import HomeLayout from "./Home";
export default function App() {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="h-screen dark:bg-slate:900">
      {currentUser ? (
        <HomeLayout currentUser={currentUser} />
      ) : (
        <Authentication />
      )}
    </div>
  );
}
