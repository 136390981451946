import React, { useState, useEffect, useContext } from "react";
import { DatabaseContext } from "../context/database";
import BasicHeader from "../components/Headers/basicHeader";
import TextAreaWithAction from "../components/Inputs/textAreaWithAction";
const WeeklyJournalForm = ({ date, user, dateTodayFormatted, week }) => {
  const {
    addWeeklyJournalEntry,
    getWeeklyJournalEntry,
    updateDailyJournalEntry,
  } = useContext(DatabaseContext);
  const [positives, setPositives] = useState("");
  const [negatives, setNegatives] = useState("");
  const [lessons, setLessons] = useState("");

  const [entryDocId, setEntryDocId] = useState(null);

  useEffect(() => {
    if (date && user && week) {
      getJournalData();
    }
  }, [date, user, week]);

  // GET DATE Entry
  const getJournalData = async () => {
    console.log("running get journal data");
    const request = await getWeeklyJournalEntry(date, user.userId, week);
    if (request && request.id) {
      setPositives(request.positives);
      setNegatives(request.negatives);
      setLessons(request.lessons);
      setEntryDocId(request.id);
    } else {
      if (entryDocId) {
        setEntryDocId(null);
      }
      setPositives("");
      setNegatives("");
      setLessons("");
    }
  };

  // POST Date Entry
  const handleSubmit = () => {
    if (entryDocId) {
      updateDailyJournalEntry(positives, negatives, lessons, entryDocId);
    } else {
      addWeeklyJournalEntry(
        positives,
        negatives,
        lessons,
        date,
        dateTodayFormatted,
        user
      );
    }
    getJournalData();
  };
  return (
    <div>
      <BasicHeader title="What went well this week?" size="lg" />

      <TextAreaWithAction
        handleSubmit={handleSubmit}
        entry={positives}
        setEntry={setPositives}
        action={false}
      />
      <br />
      <BasicHeader title="What did not go well this week?" size="lg" />

      <TextAreaWithAction
        handleSubmit={handleSubmit}
        entry={negatives}
        setEntry={setNegatives}
        action={false}
      />
      <br />
      <BasicHeader
        title="What can I learn from my success and failures this week?"
        size="lg"
      />

      <TextAreaWithAction
        handleSubmit={handleSubmit}
        entry={lessons}
        setEntry={setLessons}
        action={false}
      />
      <div className="flex justify-end mt-3">
        <button
          type="button"
          onClick={() => handleSubmit()}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none  focus:ring-cyan-500"
        >
          Post
        </button>
      </div>
    </div>
  );
};
export default WeeklyJournalForm;
