import React from "react";

const CardSkeleton = ({ spacing }) => {
  return (
    <div
      role="status"
      className={`p-4 max-w-sm rounded border border-gray-200 shadow animate-pulse md:p-6 dark:border-gray-700 ${spacing}`}
    >
      <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-700"></div>
      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>

      <span className="sr-only">Loading...</span>
    </div>
  );
};
export default CardSkeleton;
