import React from "react";
import { getAllDaysInMonth } from "../utilityFunctions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Calendar = ({ item, date, onClickDay, index }) => {
  const month = new Date();
  const dateTodayFormatted = `${month.getMonth()}_${month.getDate()}_${month.getFullYear()}`;

  const days = getAllDaysInMonth(dateTodayFormatted, item);
  return (
    <div className="text-center">
      <div className="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500 dark:text-white">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 dark:bg-slate-600 text-sm shadow ring-1 ring-gray-200 dark:ring-slate-500">
        {days.map((day, dayIdx) => {
          return (
            <button
              disabled={day.disabled}
              onClick={() => onClickDay(item, day.date, index)}
              type="button"
              key={day.date}
              className={classNames(
                "py-1.5 hover:bg-gray-100 focus:z-10 dark:hover:bg-slate-400",
                day.isCurrentMonth
                  ? "bg-white dark:bg-slate-600"
                  : "bg-slate-50 dark:bg-slate-500",
                (day.isSelected || day.isToday) && "font-semibold",
                day.isSelected && "text-white",
                !day.isSelected &&
                  day.isCurrentMonth &&
                  !day.isToday &&
                  "text-gray-900 dark:text-white",
                !day.isSelected &&
                  !day.isCurrentMonth &&
                  !day.isToday &&
                  "text-gray-400 dark:text-white",
                day.isToday && !day.isSelected && "text-cyan-600",
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === days.length - 7 && "rounded-bl-lg",
                dayIdx === days.length - 1 && "rounded-br-lg"
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  day.isSelected && day.isToday && "bg-cyan-600",
                  day.isSelected && !day.isToday && "bg-cyan-600"
                )}
              >
                {day.date.split("_")[1].replace(/^0/, "")}
              </time>
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default Calendar;
