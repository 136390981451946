import React, { useState } from "react";
import HeaderWithAction from "../components/Headers/headerWithAction";
import ActivityFeed from "../components/activityFeed";
import WeeklyJournalForm from "../forms/WeeklyJournalForm";
import SelectInput from "../components/Inputs/selectInput";

const WeeklyJournal = ({ user }) => {
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [week, setWeek] = useState("current");
  // FORMAT to MM/DD/YYYY
  const dateTodayFormatted = `${
    date.getMonth() + 1
  }_${date.getDate()}_${date.getFullYear()}`;

  // CHANGE Date and GET Entry
  const changeDate = (item, date, index) => {
    const formatted = date.replace(/_/g, "/");
    setDate(new Date(formatted));
  };
  console.log(week);
  return (
    <div>
      <HeaderWithAction
        size="2xl"
        title="Week Activity Review"
        action={
          <SelectInput
            value={week}
            setValue={setWeek}
            label=""
            name="options"
            options={["current", "past"]}
          />
        }
        border={true}
        spacing="pb-2 pt-2 mb-4"
      />
      <div className="max-h-96 overflow-auto w-full">
        <ActivityFeed week={week} />
      </div>
      <HeaderWithAction
        size="2xl"
        title="Week Review Questions"
        action={null}
        border={true}
        spacing="pb-2 pt-2 mb-4 mt-4"
      />
      <WeeklyJournalForm
        week={week}
        date={date}
        user={user}
        dateTodayFormatted={dateTodayFormatted}
      />
    </div>
  );
};
export default WeeklyJournal;
