import React, { useState, useContext, useEffect } from "react";
import { DatabaseContext } from "../context/database";
import TextInput from "../components/Inputs/textInput";
import TextAreaInput from "../components/Inputs/textAreaInput";
import SelectInput from "../components/Inputs/selectInput";
import DateInput from "../components/Inputs/dateInput";
import HeaderWithSubtitle from "../components/Headers/headerWithSubtitle";
import HeaderWithAction from "../components/Headers/headerWithAction";
import Modal from "../components/modal";
import ItemWithAction from "../components/itemWithAction";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import { useLocation, useNavigate } from "react-router-dom";

const GoalForm = () => {
  const location = useLocation();
  let navigate = useNavigate();

  const isEdit = location && location.pathname.indexOf("edit") !== -1;
  const data = location.state?.data;

  const { addGoal, updateGoal, deleteGoal } = useContext(DatabaseContext);
  const [name, setName] = useState("");
  const [why, setWhy] = useState("");
  const [affirmations, setAffirmations] = useState("");
  const [sacrafices, setSacrafices] = useState("");
  const [category, setCategory] = useState("Health");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [habits, setHabits] = useState([]);
  const [newHabit, setNewHabit] = useState("");
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (location && isEdit && data) {
      if (!updated) {
        updateState();
      }
    }
  }, [location]);
  const updateState = () => {
    setName(data.name);
    setWhy(data.why);
    setAffirmations(data.affirmations ? data.affirmations : "");
    setSacrafices(data.sacrafices);
    setCategory(data.category);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    let goalHabits = [];
    for (let habit of data.habits) {
      if (habit.goalId === data.goalId) {
        goalHabits.push(habit);
      }
    }
    setHabits(goalHabits);

    setUpdated(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let goalData = {
      name,
      why,
      sacrafices,
      affirmations,
      category,
      startDate,
      endDate,
    };
    if (name !== "") {
      if (!isEdit) {
        addGoal(goalData, habits);
      } else {
        updateGoal(goalData, habits, data.goalId);
      }
    }
    navigate("/", { replace: true });
    resetForm();
  };
  const resetForm = () => {
    setName("");
    setWhy("");
    setAffirmations("");
    setSacrafices("");
    setCategory("Health");
    setStartDate(new Date());
    setEndDate(new Date());
    setHabits([]);
    setOpen(false);
    setNewHabit("");
    setUpdated(false);
  };

  return (
    <form className="" onSubmit={handleSubmit}>
      {!isEdit ? (
        <HeaderWithSubtitle
          title="New Goal"
          subtitle="What you focus on expands"
          size="lg"
        />
      ) : (
        <HeaderWithAction
          size="2xl"
          title="Edit Goal"
          action={
            <TrashIcon
              onClick={() => deleteGoal(data.goalId, habits)}
              className="h-6 w-6 text-red-500"
              aria-hidden="true"
            />
          }
          border={true}
          spacing="pb-4"
        />
      )}
      <TextInput
        name="goal"
        value={name}
        setValue={setName}
        label="What is your goal?"
      />
      <TextAreaInput
        name="why"
        value={why}
        setValue={setWhy}
        label="Why do you want to achieve this goal?"
      />
      <TextAreaInput
        name="affirmations"
        value={affirmations}
        setValue={setAffirmations}
        label="What are some useful affirmations for this goal?"
      />
      <TextAreaInput
        name="sacrafices"
        value={sacrafices}
        setValue={setSacrafices}
        label="What are you willing to give up to make room for this goal?"
      />
      <SelectInput
        name="category"
        value={category}
        setValue={setCategory}
        label="Category"
        options={[
          "Wellness",
          "Growth",
          "Finance",
          "Career",
          "Spirituality",
          "Relationships",
          "Physical/Material",
          "Creativity",
        ]}
      />
      <div className="flex flex-row w-full gap-6">
        <DateInput
          name="start"
          value={startDate}
          setValue={setStartDate}
          label="Start Date"
        />
        <DateInput
          name="end"
          value={endDate}
          setValue={setEndDate}
          label="End Date"
        />
      </div>
      <div className="mt-6" />
      <HeaderWithAction
        title="Habits"
        action={
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="inline-flex items-center px-2 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Add Habit{" "}
          </button>
        }
        size="lg"
      />
      <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {habits &&
          habits.map((habit, index) => {
            return (
              <ItemWithAction
                item={habit.name}
                index={index}
                key={index}
                action={
                  <div className="flex flex-row items-center gap-3">
                    <PencilIcon
                      className="h-6 w-6 text-green-500"
                      onClick={() => {
                        setEditIndex(index);
                        setNewHabit(habit.name);
                        setOpen(true);
                      }}
                    />
                    <TrashIcon
                      className="h-6 w-6 text-red-500"
                      onClick={() => {
                        setHabits((prevState) => {
                          let clone = [...prevState];
                          clone.splice(index, 1);
                          return clone;
                        });
                      }}
                    />
                  </div>
                }
              />
            );
          })}
      </div>

      <Modal
        open={open}
        setOpen={() => {
          setOpen(false);
          setNewHabit("");
          if (editIndex !== null) {
            setEditIndex(null);
          }
        }}
        title="New Habit"
        btnTitle="Done"
        btnAction={() => {
          setHabits((prevState) => {
            let clone = [...prevState];
            if (editIndex !== null) {
              clone[editIndex].name = newHabit;
            } else {
              clone.push({ name: newHabit });
            }
            return clone;
          });
          if (editIndex !== null) {
            setEditIndex(null);
          }
          setNewHabit("");
          setOpen(false);
        }}
        component={<TextInput value={newHabit} setValue={setNewHabit} />}
      />

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => navigate("/", { replace: true })}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default GoalForm;
