import React from "react";
import { Tab } from "@headlessui/react";
import DailyJournal from "./DailyJournal";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import ActivityFeed from "../components/activityFeed";
import WeeklyJournal from "./WeeklyJournal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function JournalView() {
  let tabs = ["Daily", "Weekly"];
  const user = useOutletContext();

  return (
    <div className="w-full pb-10 mt-5">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-slate-100 dark:bg-slate-800 p-1">
          {tabs.map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-slate-900 dark:text-slate-300",
                  "ring-white ring-opacity-60 ring-offset-1 ring-offset-slate-400 focus:outline-none ",
                  selected
                    ? "bg-white dark:bg-slate-700 shadow"
                    : "text-slate-900 hover:bg-slate-200 dark:hover:bg-slate-700"
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {tabs.map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                "rounded-xl bg-white p-3 dark:bg-slate-800"
              )}
            >
              {tab === "Daily" && <DailyJournal user={user} />}
              {tab === "Weekly" && <WeeklyJournal user={user} />}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
