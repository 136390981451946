import React from "react";

const HeaderWithSubtitle = ({ title, subtitle, size }) => {
  return (
    <div className="w-full">
      <h3
        className={`text-${size} leading-6 font-bold text-gray-900 dark:text-white`}
      >
        {title}
      </h3>
      <p className="mt-1 text-sm text-gray-500 dark:text-white">{subtitle} </p>
    </div>
  );
};

export default HeaderWithSubtitle;
