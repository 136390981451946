import React from "react";

const ItemWithAction = ({ item, action, index }) => {
  return (
    <div
      key={index}
      className="w-full focus:outline-none flex flex-row justify-between rounded-lg border border-gray-300 bg-white dark:bg-gray-700 px-4 py-3 shadow-sm space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500"
    >
      <p className="text-md font-medium text-gray-900 dark:text-white w-auto">
        {item}
      </p>
      {action}
    </div>
  );
};

export default ItemWithAction;
