import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const DotsMenu = ({ options }) => {
  return (
    <Menu as="div" className="ml-3 relative inline-block text-left">
      <div>
        <Menu.Button className="-my-2 p-2 rounded-full bg-white flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 dark:bg-slate-800 dark:text-slate-200">
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-600 ">
          <div className="py-1">
            {options &&
              options.map((option, index) => {
                return (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <Link
                        to={option.path}
                        state={{ data: option.data }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900 dark:text-slate-200 dark:bg-slate-500"
                            : "text-gray-700 dark:text-slate-200",
                          "flex justify-between px-4 py-2 text-sm"
                        )}
                      >
                        <span>{option.name}</span>
                      </Link>
                    )}
                  </Menu.Item>
                );
              })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default DotsMenu;
