import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  HomeIcon,
  SparklesIcon,
  XIcon,
  BadgeCheckIcon,
  CogIcon,
  LightningBoltIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";

const NavLinks = () => {
  // This styling will be applied to a <NavLink> when the
  // route that it links to is currently selected.
  let activeStyle = "bg-cyan-500 text-white";

  let inactiveStyle =
    "text-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 dark:text-slate-300";

  const navItems = [
    { name: "Dashboard", path: "/", icon: HomeIcon },
    // { name: "Goals", path: "/goals", icon: LightningBoltIcon },
    // { name: "Habits", path: "/habits", icon: BadgeCheckIcon },
    { name: "Journal", path: "/journal", icon: PencilAltIcon },
    { name: "Mindset", path: "/mindset", icon: SparklesIcon },
    { name: "Settings", path: "/settings", icon: CogIcon },
  ];
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <nav className="mt-5 flex-1 px-5" aria-label="Sidebar">
      {navItems.map((item, index) => {
        return (
          <NavLink to={item.path} key={index}>
            {({ isActive }) => {
              return (
                <div
                  className={classNames(
                    isActive ? activeStyle : inactiveStyle,
                    "group flex items-center px-4 py-4 rounded-2xl flex flex-row"
                  )}
                >
                  <item.icon
                    className={classNames(
                      isActive
                        ? "text-white"
                        : "text-gray-700 dark:text-slate-300",
                      "mr-3 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="text-lg">{item.name}</span>
                </div>
              );
            }}
          </NavLink>
        );
      })}
    </nav>
  );
};
export default NavLinks;
