import React, { createContext, useState, useEffect } from "react";
// import _ from "lodash";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateEmail,
  updateProfile,
  updatePassword,
  deleteUser,
  reauthenticateWithCredential,
  browserLocalPersistence,
  setPersistence,
  signOut,
} from "firebase/auth";
import { db } from "../Firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth();

  onAuthStateChanged(auth, (u) => {
    if (u && currentUser === null) {
      setCurrentUser(u);
      console.log("sign in with id:", u.uid);
    } else {
      // User is signed out
      // ...
    }
  });
  const addUser = async (first, last, email, userId) => {
    try {
      const docRef = await addDoc(collection(db, "users"), {
        first,
        last,
        email,
        userId,
        timestamp: serverTimestamp(),
        entries: [],
      }).then((request) => {
        const docRef = doc(db, "journals", userId);

        setDoc(docRef, {
          dailyEntries: [],
          weeklyEntries: [],
          monthlyEntries: [],
          userId,
        });
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  const signIn = async (email, password, persistance) => {
    if (persistance) {
      return await setPersistence(auth, browserLocalPersistence).then(
        async () => {
          return await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              return { type: "failed", message: errorMessage };
            });
        }
      );
    } else {
      return await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          return { type: "failed", message: errorMessage };
        });
    }
  };
  const signUp = async (first, last, email, password, persistance) => {
    if (persistance) {
      return await setPersistence(auth, browserLocalPersistence).then(
        async () => {
          return await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              const user = userCredential.user;
              addUser(first, last, email, user.uid);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              return { type: "failed", message: errorMessage };
            });
        }
      );
    } else {
      return await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          addUser(first, last, email);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          return { type: "failed", message: errorMessage };
        });
    }
  };
  const updateUserProfile = () => {
    updateProfile(auth.currentUser, {
      displayName: "Jane Q. User",
      photoURL: "https://example.com/jane-q-user/profile.jpg",
    })
      .then(() => {
        // Profile updated!
        // ...
      })
      .catch((error) => {
        // An error occurred
        // ...
      });

    //TO DO
    // update document
  };
  const updateUserEmail = () => {
    updateEmail(auth.currentUser, "user@example.com")
      .then(() => {
        // Email updated!
        // ...
      })
      .catch((error) => {
        // An error occurred
        // ...
      });
  };
  const updateUserPassword = ({ newPassword }) => {
    updatePassword(currentUser, newPassword)
      .then(() => {
        // Update successful.
      })
      .catch((error) => {
        // An error ocurred
        // ...
      });
  };
  const deleteUserAccount = ({}) => {
    deleteUser(currentUser)
      .then(() => {
        // User deleted.
      })
      .catch((error) => {
        // An error ocurred
        // ...
      });
  };

  const reauthenticateUser = ({}) => {
    const credential = null;

    reauthenticateWithCredential(currentUser, credential)
      .then(() => {
        // User re-authenticated.
      })
      .catch((error) => {
        // An error ocurred
        // ...
      });
  };
  const signOutUser = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        window.location.reload();
        console.log("success");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <AuthContext.Provider
      value={{ signIn, signUp, updateUserPassword, currentUser, signOutUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
