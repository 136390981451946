import React, { useContext, useEffect, useState } from "react";
import { DatabaseContext } from "../context/database";
import { organizeActivity } from "../utilityFunctions";

const ActivityFeed = ({ week }) => {
  const { getActivity } = useContext(DatabaseContext);
  const [activity, setActivity] = useState(null);
  useEffect(() => {
    if (week) {
      getState();
    }
  }, [week]);

  const getState = async () => {
    const request = await getActivity(week);
    if (request) {
      const organized = organizeActivity(request);
      if (organized) {
        setActivity(organized);
      }
    }
  };

  return (
    <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-2">
      {activity &&
        Object.keys(activity).map((date, index) => {
          return (
            <li className="mb-10 ml-4" key={index}>
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                {date}
              </time>
              {Object.keys(activity[date]).map((type, index) => {
                return (
                  <div key={index + "t"}>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      {type === "habit" ? "Completed Habits" : "Journal Entry"}
                    </h3>
                    {type === "habit" ? (
                      <>
                        {activity[date][type].map((text, i) => {
                          return (
                            <p
                              className="mb-1 text-base font-normal text-gray-500 dark:text-gray-400"
                              key={i + "h"}
                            >
                              {text}
                            </p>
                          );
                        })}
                      </>
                    ) : (
                      <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        {activity[date][type].split("|")[0]} <br />
                        {activity[date][type].split("|")[1]}
                      </p>
                    )}
                  </div>
                );
              })}
            </li>
          );
        })}
    </ol>
  );
};
export default ActivityFeed;
