import React from "react";

const HeaderWithAction = ({
  title,
  action,
  size,
  border,
  spacing,
  subtext,
}) => {
  return (
    <div
      className={`${
        border && "border-b border-gray-200 dark:border-white"
      } flex items-center justify-between dark:text-white ${spacing}`}
    >
      <div className="flex flex-col justify-center">
        <h3
          className={`text-${size} dark:text-white leading-6 font-medium text-gray-900`}
        >
          {title}
        </h3>
        {subtext}
      </div>
      <div className="mt-3 sm:mt-0 sm:ml-4">{action}</div>
    </div>
  );
};
export default HeaderWithAction;
