import React from "react";

const CheckboxInput = ({ value, setValue }) => {
  return (
    <input
      id="checkbox"
      aria-describedby="checkbox-description"
      name="checkbox"
      type="checkbox"
      checked={value}
      onChange={(e) => setValue(e.target.checked)}
      className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300 rounded"
    />
  );
};
export default CheckboxInput;
