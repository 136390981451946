import React from "react";
import NavActions from "./navActions";
import NavButton from "./navButton";
const MovileNavBar = ({ user }) => {
  return (
    <div className=" w-full flex flex-row justify-between items-center">
      <NavActions user={user} spacing="gap-2" size="sm" />
      <NavButton user={user} />
    </div>
  );
};
export default MovileNavBar;
