import React, { useState } from "react";
import ProgressBar from "./progressBar";
import { MinusSmIcon } from "@heroicons/react/outline";
import Accordion from "./accordion";
import HeaderWithAction from "./Headers/headerWithAction";
import DotsMenu from "./dotsMenu";
import Become from "../assets/become.png";
import { getGoalProgress } from "../utilityFunctions";
const GoalCard = ({
  name,
  category,
  why,
  sacrafices,
  startDate,
  affirmations,
  endDate,
  id,
  timestamp,
  userId,
  habits,
  collapseAll,
}) => {
  return (
    <div className="flex flex-col">
      <div className="block p-6 max-w-sm bg-white rounded-lg  shadow-sm  dark:bg-slate-800 ring-1 ring-slate-900/5 dark:shadow-slate-800/80 flex flex-col justify-between">
        <HeaderWithAction
          size="xl"
          title={name}
          subtext={
            <div className="flex flex-row items-center">
              <span className="text-sm font-normal text-gray-600 dark:text-slate-300 ">
                {new Date(startDate).toLocaleDateString("en-us", {
                  month: "short",
                  day: "numeric",
                })}
              </span>
              <MinusSmIcon className="h-4 w-4 text-slate-800 dark:text-white" />
              <span className="text-sm font-normal text-gray-600 dark:text-slate-300 ">
                {new Date(endDate).toLocaleDateString("en-us", {
                  month: "short",
                  day: "numeric",
                })}{" "}
              </span>
            </div>
          }
          action={
            <DotsMenu
              options={[
                {
                  name: "Edit",
                  path: "edit",
                  data: {
                    name,
                    category,
                    why,
                    sacrafices,
                    affirmations,
                    startDate,
                    endDate,
                    goalId: id,
                    userId,
                    timestamp,
                    habits,
                  },
                },
              ]}
            />
          }
          border={false}
          spacing="pb-4"
        />
        {!collapseAll && (
          <div>
            <Accordion
              items={[
                { title: "Why this goal is important", text: why },
                {
                  title: "Affirmations",
                  text: affirmations ? affirmations : "",
                },
                { title: "What I am willing to give up", text: sacrafices },
              ]}
            />
          </div>
        )}
        <div className="flex flex-row items-center gap-2 mt-4">
          <ProgressBar percentage={getGoalProgress(startDate, endDate)} />
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-fuchsia-400 text-fuchsia-900">
            {category}
          </span>
        </div>
      </div>
    </div>
  );
};
export default GoalCard;
