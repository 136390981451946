import React from "react";
import { PlusSmIcon as PlusSmIconSolid } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
const PlusButton = ({ path }) => {
  return (
    <Link
      to={path}
      className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-cyan-500 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
    >
      <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
    </Link>
  );
};
export default PlusButton;
