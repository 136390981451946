import React, { useState } from "react";
import useDarkSide from "../useDarkSide";
import Toggle from "./toggle";
import { SunIcon, MoonIcon } from "@heroicons/react/outline";
const ToggleTheme = ({ containerDimensions, iconDimensions }) => {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );
  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };
  return (
    <div
      className={` ${containerDimensions} rounded-2xl dark:bg-slate-900 border-2 dark:border-slate-600 shadow-xl flex items-center justify-center hover:bg-slate-200 dark:hover:bg-slate-300 hover:cursor-pointer`}
      onClick={() => toggleDarkMode(!darkSide)}
    >
      {colorTheme === "dark" ? (
        <MoonIcon className={`${iconDimensions}  text-slate-500`} />
      ) : (
        <SunIcon className={`${iconDimensions}  text-slate-500`} />
      )}
    </div>
  );
};
export default ToggleTheme;
