import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/auth";
import Notification from "../components/notification";

const Authentication = ({}) => {
  const { signIn, signUp, updateUserPassword } = useContext(AuthContext);
  const [isSignIn, setIsSignIn] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [password, setPassword] = useState("");
  const [saveLogin, setSaveLogin] = useState(true);

  const onChangeSaveLogin = (e) => {
    setSaveLogin(!saveLogin);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangeFirst = (e) => {
    setFirst(e.target.value);
  };
  const onChangeLast = (e) => {
    setLast(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let request;
    if (isSignIn) {
      request = await signIn(email, password, saveLogin);
    } else {
      request = await signUp(first, last, email, password, saveLogin);
    }

    if (request && request.type && request.type !== "success") {
      setError({ type: "failed", message: request.message });
    }
  };

  const handleReset = () => {
    if (email) {
      updateUserPassword(email);
      setError({
        type: "success",
        message: "Check your email for a password reset link.",
      });
    } else {
      setError({
        type: "failed",
        message: "Enter Email Adress To Reset Password",
      });
    }
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 dark:bg-slate-900">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-2 mb-5 text-center text-3xl font-extrabold text-gray-900">
              {isSignIn ? "Sign in to your account" : "Sign Up"}
            </h2>
          </div>
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div>
              {error && <Notification error={error} setError={setError} />}
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={onChangeEmail}
                />
              </div>
            </div>
            {!isSignIn && (
              <>
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="first-name"
                      name="firstName"
                      type="text"
                      autoComplete="first-name"
                      required
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
                      placeholder="Joe"
                      value={first}
                      onChange={onChangeFirst}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="last-name"
                      name="lastName"
                      type="text"
                      autoComplete="last-name"
                      required
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
                      placeholder="Dispenza"
                      value={last}
                      onChange={onChangeLast}
                    />
                  </div>
                </div>
              </>
            )}
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={onChangePassword}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-cyan-500 focus:ring-cyan-500 border-gray-300 rounded"
                  checked={saveLogin}
                  onChange={onChangeSaveLogin}
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a
                  onClick={() => handleReset()}
                  className="font-medium text-cyan-500 hover:text-cyan-300"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-500 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
              >
                {isSignIn ? "Sign In" : "Sign Up"}
              </button>

              <p className="mt-2 text-center text-sm text-gray-600">
                or{" "}
                <button
                  type="button"
                  onClick={() => setIsSignIn(!isSignIn)}
                  className="inline-flex items-center px-2.5 py-1.5  text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isSignIn ? "Sign Up Now" : "Sign In"}
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Authentication;
