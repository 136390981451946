import React from "react";

const BasicHeader = ({ title, size }) => {
  return (
    <h3
      className={`text-${size} font-medium leading-6 text-gray-900 mb-1 dark:text-slate-300`}
    >
      {title}
    </h3>
  );
};
export default BasicHeader;
