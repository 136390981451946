import React, { onFocus } from "react";

const DateInput = ({ value, setValue, label, name }) => {
  return (
    <div className="mt-4 w-full">
      <label
        htmlFor={`${name}-name`}
        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          type="date"
          value={value}
          onFocus={(event) => event.target.showPicker()}
          onChange={(e) => setValue(e.target.value)}
          name={`${name}-name`}
          id="datePicker"
          autoComplete={`${name}-name`}
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-cyan-500 dark:focus:border-cyan-500"
        />
      </div>
    </div>
  );
};

export default DateInput;
