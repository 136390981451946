import React, { useState, useEffect, useContext } from "react";
import Goals from "./Goals";
import Habits from "./Habits";
import { useOutletContext } from "react-router-dom";
import { DatabaseContext } from "../context/database";
import { AuthContext } from "../context/auth";
import { currentStreak } from "../utilityFunctions";
import HeaderWithGridCard from "../components/headerWithGridCard";

const Dashboard = ({}) => {
  const user = useOutletContext();
  const { getGoals, getHabits, completedHabit } = useContext(DatabaseContext);

  const [goals, setGoals] = useState(null);
  const [habits, setHabits] = useState(null);

  const [loadingGoals, setLoadingGoals] = useState(false);
  const [loadingHabits, setLoadingHabits] = useState(false);
  const [date, setDate] = useState(new Date());

  const dateTodayFormatted = `${
    date.getMonth() + 1
  }_${date.getDate()}_${date.getFullYear()}`;
  useEffect(() => {
    if (user) {
      if (goals == null) {
        handleGetGoals();
      }
    }
  }, [user]);
  const handleGetGoals = async () => {
    console.log("Getting Goals");

    setLoadingGoals(true);
    const request = await getGoals();
    if (request && request.length > 0) {
      setGoals(request);
      handleGetHabits(request);
    }
    setLoadingGoals(false);
  };
  const handleGetHabits = async (goalsArray) => {
    console.log("Getting Habits");
    setLoadingHabits(true);
    let goalHabits = [];
    for (let goal of goalsArray) {
      const request = await getHabits(goal.id);
      if (request.length > 0) {
        for (let habit of request) {
          const streak = currentStreak(habit.completed);
          goalHabits.push({ ...habit, streak });
        }
      }
    }
    if (goalHabits.length > 0) {
      setHabits(goalHabits);
    }
    setLoadingHabits(false);
  };
  const updateHabitState = async (value, index) => {
    await completedHabit(habits[index], value, dateTodayFormatted);

    setHabits((prevState) => {
      let state = [...prevState];
      if (value) {
        state[index].completed.push(dateTodayFormatted);
      } else {
        let spliceIndex = state[index].completed.indexOf(dateTodayFormatted);
        state[index].completed.splice(spliceIndex, 1);
      }
      console.log("returning state", state);
      return state;
    });
  };
  const markComplete = async (completed, date, index) => {
    const exists = completed.indexOf(date) !== -1;

    await completedHabit(habits[index], !exists, date);

    setHabits((prevState) => {
      let state = [...prevState];
      if (!exists) {
        state[index].completed.push(date);
      } else {
        let spliceIndex = state[index].completed.indexOf(date);
        state[index].completed.splice(spliceIndex, 1);
      }
      return state;
    });
  };
  console.log(goals, habits);
  return (
    <div className="pb-10 mt-5">
      {user && (
        <>
          <HeaderWithGridCard
            title="Goals"
            loading={loadingGoals}
            list={goals}
            date={date}
            updateState={null}
            markComplete={null}
            habits={habits}
          />
          <br />
          <br />
          <HeaderWithGridCard
            title="Habits"
            loading={loadingHabits}
            list={habits}
            date={date}
            updateState={updateHabitState}
            markComplete={markComplete}
          />
        </>
      )}
    </div>
  );
};
export default Dashboard;
