/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import {
  HomeIcon,
  SparklesIcon,
  XIcon,
  BadgeCheckIcon,
  CogIcon,
  LightningBoltIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";
const navItems = [
  { name: "Dashboard", path: "/", icon: HomeIcon },
  // { name: "Goals", path: "/goals", icon: LightningBoltIcon },
  { name: "Journal", path: "/journal", icon: PencilAltIcon },
  { name: "Mindset", path: "/mindset", icon: SparklesIcon },
  { name: "Settings", path: "/settings", icon: CogIcon },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
let activeStyle = "bg-cyan-500 text-white";

let inactiveStyle =
  "text-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 dark:text-slate-300";
export default function NavButton({ user }) {
  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div>
        <Menu.Button className="dark:text-slate-400 px-4 py-2 text-sm font-medium nline-flex w-full justify-center rounded-md  dark:bg-slate-900 border-2 dark:border-slate-600 shadow-xl flex items-center justify-center hover:bg-slate-200 dark:hover:bg-slate-300 hover:cursor-pointer">
          Nav Menu
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="dark:bg-slate-900 absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-3">
            <p className="text-sm dark:text-slate-300">Signed in as</p>
            <p className="truncate text-sm font-medium text-gray-900 dark:text-slate-300">
              {user && user.email}
            </p>
          </div>
          <div className="py-1">
            {navItems.map((item, index) => {
              return (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <NavLink to={item.path}>
                      {({ isActive }) => {
                        return (
                          <div
                            className={classNames(
                              isActive ? activeStyle : inactiveStyle,
                              "group flex items-center flex flex-row pl-2 py-1"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                isActive
                                  ? "text-white"
                                  : "text-gray-700 dark:text-slate-300",
                                "mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span className="text-lg">{item.name}</span>
                          </div>
                        );
                      }}
                    </NavLink>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
