import { Fragment, useState, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TextAreaWithAction({
  handleSubmit,
  entry,
  setEntry,
  action,
}) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className="relative"
    >
      <div className="dark:bg-slate-600 border dark:border-0 rounded-lg shadow-sm overflow-hidden ">
        <label htmlFor="comment" className="sr-only">
          Add your comment
        </label>
        <textarea
          rows={3}
          name="comment"
          id="comment"
          className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm dark:bg-slate-600 dark:text-white"
          placeholder="Add your comment..."
          value={entry}
          onChange={(e) => setEntry(e.target.value)}
        />
        {action && (
          <div className="flex justify-end pr-2 pb-2">
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none  focus:ring-cyan-500"
            >
              Post
            </button>
          </div>
        )}
      </div>
    </form>
  );
}
