/* This example requires Tailwind CSS v2.0+ */
import { PlusIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function EmptyState({
  icon,
  title = "Empty State",
  text = "click to get started",
  btnAction = () => null,
  btnTitle = "New",
  btn = true,
}) {
  return (
    <div className="text-center py-5 h-auto w-auto bg-white dark:bg-gray-700 rounded">
      <div className="flex justify-center items-center">{icon}</div>
      <h3 className="mt-2 text-lg font-bold text-gray-900 dark:text-white">
        {title}
      </h3>
      <p className="mt-1 text-sm text-gray-500 dark:text-gray-100">{text}</p>
      {btn && (
        <div className="mt-6">
          <Link
            to="new"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {btnTitle}
          </Link>
        </div>
      )}
    </div>
  );
}
